import Cookies from 'js-cookie';

export const getJwtToken = () => Cookies.get('token');
export const getUserId = () => Cookies.get('user_id');

export const getHeaders = () => {
    let headers = {};
    const token = getJwtToken();
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
};