import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Snackbar,
    Alert,
    CircularProgress,
    Switch,
    FormControlLabel
} from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';
import OrderDetailModal from '../components/OrderDetailModal';
import OrderClient from '../clients/OrderClient';


import useAuth from '../hooks/useAuth';

import './OrderGrid.css';

function OrderGrid() {
    const rows = [];
    const [isLoading, setIsLoading] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [autoReload, setAutoReload] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [massAction, setMassAction] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const { auth } = useAuth();

    useEffect(() => {
        const autoReloadInterval = 5000;
        let intervalId = null;

        if (autoReload) {
            intervalId = setInterval(() => {
                setReloadTrigger((cur) => cur + 1);
            }, autoReloadInterval);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [autoReload]);

    const handleAutoReloadChange = (event) => {
        setAutoReload(event.target.checked);
    };

    const onRowClick = (params) => {
        setSelectedRow(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    const handleSelectionModelChange = (selectionModel) => {
        setSelectedOrders(selectionModel);
    };

    const handleMassActionChange = (event) => {
        setMassAction(event.target.value);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleMassActionSubmit = async () => {
        try {
            setIsLoading(true);
            let response;
            switch (massAction) {
                case 'checkCompliance':
                    response = await OrderClient.checkCompliance(selectedOrders);
                    break;
                case 'commitOrders':
                    response = await OrderClient.commitOrders(selectedOrders);
                    break;
                case 'forceCompliance':
                    response = await OrderClient.forceCompliance(selectedOrders);
                    break;
                default:
                    break;
            }

            if (response) {
                const { successCount, failureCount, errorMessage, serverError } = response;
                if (errorMessage) {
                    console.debug('Error details:', errorMessage);
                }
                if (serverError) {
                    showSnackbar('Error performing mass action - check server logs.', 'error');
                } else {
                    let message = `${successCount} order(s) processed successfully.`;
                    if (failureCount > 0) {
                        message += ` ${failureCount} order(s) failed.`;
                    }
                    showSnackbar(message, failureCount > 0 ? 'warning' : 'success');
                }
            }
        } catch (error) {
            showSnackbar('Error performing mass action.', 'error');
            console.debug('Error performing mass action');
        }

        setReloadTrigger((cur) => cur + 1);
        setIsLoading(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 200,
            type: 'date',
            filterable: true,
            valueFormatter: (value) => {
                const date = new Date(value);
                return date.toLocaleString();
            },
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 200,
            type: 'date',
            filterable: true,
            valueFormatter: (value) => {
                const date = new Date(value);
                return date.toLocaleString();
            },
        },
        { field: 'order_name', headerName: 'Order ID', width: 100 },
        {
            field: 'compliance_status',
            headerName: 'Compliance Status',
            width: 150,
            cellClassName: (params) => {
                if (params.value === 'Pending') return 'pending';
                if (params.value === 'Error' || params.value === 'NotCompliant') return 'error';
                if (['Compliant' , 'Forced', 'Bypassed'].includes(params.value)) return 'compliant';
                return '';
            },
        },
        {
            field: 'commit_status',
            headerName: 'Commit Status',
            width: 150,
            valueFormatter: (status) => {
                if (status) {
                    const words = status.split('-');
                    const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
                    return formattedWords.join(' ');
                }
                return '';
            },
            cellClassName: (params) => {
                if (params.value === 'pending') return 'pending';
                if (params.value === 'error') return 'error';
                if (params.value === 'committed' || params.value === 'force-committed') return 'committed';
                return '';
            },
        },
        { field: 'email', headerName: 'Email', width: 300 },
        { field: 'order_firstname', headerName: 'First Name', width: 130 },
        { field: 'order_lastname', headerName: 'Last Name', width: 130 },
        { field: 'order_shipping_state', headerName: 'Shipping State', width: 130 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            renderCell: (params) => {
                const { id, commit_status } = params.row;

                const handleAction = async (actionType) => {
                    try {
                        setIsLoading(true);
                        const response = await OrderClient[actionType]([id]);
                        const { successCount, failureCount, errorMessage, serverError } = response;
                        if (errorMessage) {
                            console.debug('Error details:', errorMessage);
                        }
                        if (serverError) {
                            showSnackbar('Error performing mass action - check server logs.', 'error');
                        } else {
                            let message = `${successCount} order(s) processed successfully.`;
                            if (failureCount > 0) {
                                message += ` ${failureCount} order(s) failed.`;
                            }
                            showSnackbar(message, failureCount > 0 ? 'warning' : 'success');
                        }
                        setReloadTrigger((cur) => cur + 1);
                    } catch (error) {
                        showSnackbar('Error performing mass action', 'error');
                        console.debug(`Error executing ${actionType}`, error);
                    } finally {
                        setIsLoading(false);
                    }
                };

                const checkDisabled = () => ['force-committed', 'committed'].includes(commit_status);
                const commitDisabled = () => ['force-committed', 'committed'].includes(commit_status);
                const forceCommitDisabled = () => ['force-committed', 'committed'].includes(commit_status);

                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                            sx={{ marginTop: 1 }}
                            disabled={checkDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('checkCompliance') }}
                            size="small">
                            Check Compliance
                        </Button>
                        <Button
                            disabled={commitDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('commitOrders') }}
                            size="small">
                            Commit Order
                        </Button>
                        <Button
                            disabled={forceCommitDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('forceCompliance') }}
                            size="small">
                            Force Compliance
                        </Button>
                    </Box>
                );
            },

        },
    ];

    return (
        <>
            <Box p={2}>
                <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="mass-action-label">Select Mass Action</InputLabel>
                        <Select
                            labelId="mass-action-label"
                            value={massAction}
                            onChange={handleMassActionChange}
                            label="Select Mass Action"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="checkCompliance">Check Compliance</MenuItem>
                            <MenuItem value="commitOrders">Commit Orders</MenuItem>
                            <MenuItem value="forceCompliance">Force Compliance</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleMassActionSubmit} disabled={!massAction || selectedOrders.length === 0}>
                        Submit
                    </Button>
                    {isLoading && <CircularProgress size={20} />}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoReload}
                                onChange={handleAutoReloadChange}
                                color="primary"
                            />
                        }
                        label="Auto Reload"
                    />
                </Box>
                <AbstractGrid
                    dataType="order"
                    columns={columns}
                    rows={rows}
                    onRowClick={onRowClick}
                    onSelectionModelChange={handleSelectionModelChange}
                    checkboxSelection={true}
                    reloadTrigger={reloadTrigger}
                    rowHeight={135}
                />
            </Box>
            <OrderDetailModal
                open={isModalOpen}
                onClose={handleCloseModal}
                rowData={selectedRow}
                recordId={selectedRow?.id}
                model={'order'}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default OrderGrid;