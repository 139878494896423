import React from 'react';
import { useMessage } from '../context/MessageProvider';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MessageDisplay = () => {
    const { messages, removeMessage } = useMessage();

    const handleClose = (id) => {
        removeMessage(id);
    };

    return (
        <>
            {messages.map((message) => (
                <Snackbar
                    key={message.id}
                    open={true}
                    autoHideDuration={3000}
                    onClose={() => handleClose(message.id)}
                    message={message.message}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => handleClose(message.id)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            ))}
        </>
    );
};

export default MessageDisplay;