import { useEffect } from 'react';

import { useUserContext } from '../context/UserProvider';
import { useAuthContext } from '../context/AuthProvider';

import axios from 'axios';
import Cookies from 'js-cookie';

import config from '../config.json';
import { getHeaders, getJwtToken } from '../utility/auth';

export const useAutoLogin = () => {
    const baseUrl = config.apiUrl;
    const [, setAuthStateWithMessage] = useAuthContext();
    const [user, setUserState] = useUserContext();

    const token = getJwtToken();
    const userId = user?.user?.id;

    useEffect(() => {
        if (getJwtToken() && !userId) {
            const fetchUserInfo = async () => {
                try {
                    const response = await axios.get(`${baseUrl}user/`, { headers: getHeaders() });
                    const { user } = response.data;

                    setAuthStateWithMessage(
                        { token, user: user?.id },
                        'Login successful',
                        'success'
                    );

                    setUserState({ user });
                } catch (err) {
                    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                        Cookies.remove('token', { path: '/' });
                        Cookies.remove('userId', { path: '/' });
                        return;
                    }

                    console.debug(err);
                }
            }

            fetchUserInfo();
        };
    // eslint-disable-next-line
    }, [token, userId]);
};
