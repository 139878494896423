import React, { useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONPretty from 'react-json-pretty';
// More info on styling the json box can be found here https://www.npmjs.com/package/react-json-pretty
import 'react-json-pretty/themes/monikai.css';

import { formatDate } from '../utility/utility';

import useRecord from '../hooks/useRecord';

import './OrderDetailModal.css';

function OrderDetailModal({ open, onClose, model, recordId }) {
    const { record, loading, error } = useRecord(model, recordId, open ? 1 : 0);

    const getComplianceDescription = (complianceResponse) => {
        if (complianceResponse.salesOrder?.complianceStatus === "NotCompliant") {
            const rules = complianceResponse.salesOrder.shipments?.[0]?.rules || [];
            return (
                <dl className='compliance-description'>
                    {rules.filter(rule => !['Compliant', 'Bypassed'].includes(rule.complianceStatus))
                        .map((rule, index) => (
                        <React.Fragment key={index}>
                            <dt className='compliance-description-heading'>{rule.complianceDescription}</dt>
                            <dd className='compliance-description-content'>{rule.ruleDescription}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            );
        }
        return null;
    };

    const getErrorDescription = (errorResponse) => {
        if (Array.isArray(errorResponse.errors)) {
            const filteredErrors = errorResponse.errors.filter(error =>
                !error.message.includes("SalesOrder has been committed")
            );
            if (filteredErrors.length === 0) return null;
            return (
                <dl className='compliance-description'>
                    {filteredErrors.map((error, index) => (
                        <React.Fragment key={index}>
                            <dt className='compliance-description-heading'>
                                Error {error?.code ? `(${error?.code})` : ''}
                            </dt>
                            <dd>{error.message}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            );
        } else if (errorResponse.message && !errorResponse.message.includes("SalesOrder has been committed")) {
            return (
                <dl className='compliance-description'>
                    <dt className='compliance-description-heading'>
                        Error {errorResponse?.code ? `(${errorResponse?.code})` : ''}
                    </dt>
                    <dd className='compliance-description-content'>{errorResponse.message}</dd>
                </dl>
            );
        }
        return null;
    };
    
    const getDescription = (record) => {
        if (!record) return null;
        let description = null;
        if (record.compliance_response) {
            try {
                const complianceResponse = JSON.parse(record.compliance_response);
                description = getComplianceDescription(complianceResponse);
            } catch (error) {
                console.error("Error parsing compliance_response:", error);
            }
        }

        if (!description && record.error) {
            try {
                const errorResponse = JSON.parse(record.error);
                description = getErrorDescription(errorResponse);
            } catch (error) {
                console.error("Error parsing error response:", error);
            }
        }

        return description || '';
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Order #{record ? record.order_name : 'Loading...'}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                ) : error ? (
                    <Typography color="error">Error loading record.</Typography>
                ) : record ? (
                    <div>
                        <Typography variant="subtitle1">Basic Information:</Typography>
                        <p>Compliance ID: {record.id}</p>
                        <p>Created At: {formatDate(record.created_at)}</p>
                        <p>Updated At: {formatDate(record.updated_at)}</p>
                        <p>Order ID: {record.order_name}</p>
                        <p>Compliance Status: {record.compliance_status}</p>
                        <p>{getDescription(record) && 'Compliance Description:'}</p>
                        {getDescription(record)}
                        <p>Email: {record.email}</p>
                        <p>First Name: {record.order_firstname}</p>
                        <p>Last Name: {record.order_lastname}</p>

                        <Typography variant="subtitle1">Request Information:</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Compliance Request</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.compliance_request} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Compliance Response</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.compliance_response} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Commit Request</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.commit_request} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Commit Response</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.commit_response} />
                            </AccordionDetails>
                        </Accordion>

                        <Typography variant="subtitle1">Errors</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Error</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.error} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ) : (
                    <Typography>No data available.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default OrderDetailModal;