import { useState, useEffect } from 'react';
import axios from 'axios';

import useAuth from './useAuth';
import { useMessage } from '../context/MessageProvider';

import { getHeaders } from '../utility/auth';
import config from '../config.json';

function useRecord(model, id, trigger = 0) {
    const baseUrl = config.apiUrl;
    const [record, setRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { logout } = useAuth();
    const { sendMessage } = useMessage();

    useEffect(() => {
        if (!model || !id) {
            setRecord(null);
            setLoading(false);
            return;
        }

        const fetchRecord = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}record/${model}/${id}`, {
                    headers: getHeaders(),
                });
                setRecord(response.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    await logout();
                    sendMessage('Session expired. Please login again.', 'error');
                } else if (err.response && err.response.status === 404) {
                    sendMessage('Record not found.', 'error');
                } else {
                    console.error(err);
                    sendMessage('Something went wrong while fetching the record.', 'error');
                }
            }
        };

        fetchRecord();
    // eslint-disable-next-line
    }, [model, id, baseUrl, trigger]);

    const reload = () => {
        setRecord(null);
    };

    return { record, loading, error, reload };
}

export default useRecord;
