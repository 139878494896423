import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONPretty from 'react-json-pretty';
//More info on styling the json box can be found here https://www.npmjs.com/package/react-json-pretty
import 'react-json-pretty/themes/monikai.css';

import { formatDate } from '../utility/utility';

function EmailDetailModal({ open, onClose, rowData }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Email Log #{rowData && rowData.id}</DialogTitle>
            <DialogContent>
                {rowData && (
                    <div>
                        <Typography variant="subtitle1">Basic Information:</Typography>
                        <p>Sent: {formatDate(rowData.created_at)}</p>
                        <p>Status: {rowData.status}</p>
                        <p>Email Type: {rowData.email_type}</p>
                        <p>Resent: {rowData.resent ? 'Yes' : 'No'}</p>

                        <Typography variant="subtitle1">Email Information:</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Options</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.options} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Error</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.error} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmailDetailModal;