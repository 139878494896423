import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userState, setUserState] = useState({
        user: {},
    });

    return (
        <UserContext.Provider value={[userState, setUserState]}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);