import React from 'react';

import { Box } from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 200,
        type: 'date',
        filterable: false,
        valueFormatter: (value) => {
            const date = new Date(value);
            return date.toLocaleString();
        },
    },
    { field: 'notified', headerName: 'Notified', width: 100 },
    { field: 'message', headerName: 'Message', width: 200, flex: 1 },
];

const rows = [];

const onRowClick = (event) => {
    console.debug(event);
};

function ErrorLogGrid() {
    return (
        <Box p={2}>
            <AbstractGrid
                dataType="error_log"
                columns={columns}
                rows={rows}
                onRowClick={onRowClick}
            />
        </Box>
    );
}

export default ErrorLogGrid;