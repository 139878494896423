import React, { createContext, useContext, useState } from 'react';
import { useMessage } from './MessageProvider';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: null,
        user: null,
    });
    const { sendMessage } = useMessage();

    const setAuthStateWithMessage = (newState, message, type) => {
        setAuthState(newState);
        sendMessage(message, type);
    };

    return (
        <AuthContext.Provider value={[authState, setAuthStateWithMessage]}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);