import axios from 'axios';

import config from '../config.json';
import { getHeaders, getUserId } from '../utility/auth';

class OrderClient {
    constructor() {
        this.baseUrl = `${config.apiUrl}orders`;
    }

    async checkCompliance(orderIds) {
        try {
            const response = await axios.post(
                `${this.baseUrl}/check-compliance`,
                { orderIds, userId: getUserId() },
                { headers: getHeaders() }
            );
            return response.data;
        } catch (error) {
            console.error('Error checking compliance:', error);
            return error.response ? error.response.data : { error: 'An error occurred' };
        }
    }

    async commitOrders(orderIds) {
        try {
            const response = await axios.post(
                `${this.baseUrl}/commit`,
                { orderIds, userId: getUserId() },
                { headers: getHeaders() }
            );
            return response.data;
        } catch (error) {
            console.error('Error committing orders:', error);
            return error.response ? error.response.data : { error: 'An error occurred' };
        }
    }

    async forceCompliance(orderIds) {
        try {
            const response = await axios.post(
                `${this.baseUrl}/force-compliance`,
                { orderIds, userId: getUserId() },
                { headers: getHeaders() }
            );
            return response.data;
        } catch (error) {
            console.error('Error forcing compliance:', error);
            return error.response ? error.response.data : { error: 'An error occurred' };
        }
    }
}

// eslint-disable-next-line
export default new OrderClient();