import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONPretty from 'react-json-pretty';
//More info on styling the json box can be found here https://www.npmjs.com/package/react-json-pretty
import 'react-json-pretty/themes/monikai.css';

import { formatDate } from '../utility/utility';

function WebhookDetailModal({ open, onClose, rowData }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Webhook #{rowData && rowData.id}</DialogTitle>
            <DialogContent>
                {rowData && (
                    <div>
                        <Typography variant="subtitle1">Basic Information:</Typography>
                        <p>Received At: {formatDate(rowData.received_at)}</p>
                        <p>Webhook ID: {rowData.webhook_id}</p>
                        <p>Object ID: {rowData.object_id}</p>
                        <p>Type: {rowData.type}</p>
                        <p>Status: {rowData.status}</p>

                        <Typography variant="subtitle1">Webhook Information:</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Body</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.body} />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Error</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={rowData.error} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default WebhookDetailModal;