import React from 'react';
import { Navigate } from 'react-router-dom';
import {
    Box,
    Typography
} from '@mui/material';

import useAuth from '../hooks/useAuth';

function ProtectedRoute({ element: Component }) {
    const { auth } = useAuth();
    
    if (!auth?.token) {
        return (
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100vh' 
                }}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    BEVFLOW.IO
                </Typography>
                <Typography variant="h5">
                    Please login to continue
                </Typography>
            </Box>
        );
    }
    
    return auth?.token ? <Component /> : <Navigate to="/" replace />;
}

export default ProtectedRoute;
