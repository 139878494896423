import React from 'react';

import { Box } from '@mui/material';

import { useUserContext } from '../context/UserProvider';

function ProfilePage() {

    const [user,] = useUserContext();

    let userInfo = user?.user;

    const role = userInfo => {
        if(!userInfo.role) return 'N/A';
        return userInfo.role.split('_')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ');
    }

    return (
        <Box p={2}>
            <h1>Profile</h1>
            <p>Name : {userInfo?.first_name} {userInfo?.last_name}</p>
            <p>Email : {userInfo?.email}</p>
            <p>Role : {role(userInfo)} </p>
        </Box>
    );
}

export default ProfilePage;