import React, { useState } from 'react';

import { Box } from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';
import RequestLogDetailModal from '../components/RequestLogDetailModal';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'sent_at',
        headerName: 'Sent',
        width: 200,
        type: 'date',
        filterable: false,
        valueFormatter: (value) => {
            const date = new Date(value);
            return date.toLocaleString();
        },
    },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'endpoint', headerName: 'Endpoint', width: 500 },
    { field: 'object_id', headerName: 'Object ID', width: 150 },
    { field: 'object_type', headerName: 'Object Type', width: 150 },
];

const rows = [];

function RequestLogGrid() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const onRowClick = (params) => {
        setSelectedRow(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    return (
        <>
            <Box p={2}>
                <AbstractGrid
                    dataType="request_log"
                    columns={columns}
                    rows={rows}
                    onRowClick={onRowClick}
                />
            </Box>
            <RequestLogDetailModal
                open={isModalOpen}
                onClose={handleCloseModal}
                rowData={selectedRow}
            />
        </>
    );
}

export default RequestLogGrid;