import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext();

export const useMessage = () => useContext(MessageContext);

export const MessageProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);

    const sendMessage = (message, type) => {
        setMessages((prevMessages) => [...prevMessages, { id: Date.now(), message, type }]);
    };

    const removeMessage = (id) => {
        setMessages((prevMessages) => prevMessages.filter((message) => message.id !== id));
    };

    return (
        <MessageContext.Provider value={{ messages, sendMessage, removeMessage }}>
            {children}
        </MessageContext.Provider>
    );
};