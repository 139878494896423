export const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: 'America/Los_Angeles',
        timeZoneName: 'short',
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
}

export const capitalizeWords = (str) => {
    return str.split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};