import { useState } from 'react';

import axios from 'axios';

import useAuth from './useAuth';

import { useUserContext } from '../context/UserProvider';
import { useMessage } from '../context/MessageProvider';

import config from '../config.json';
import { getHeaders } from '../utility/auth';

function useUser() {
    const baseUrl = config.apiUrl;

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const [userContext, setUserContext] = useUserContext();

    const { logout } = useAuth();
    const { sendMessage } = useMessage();

    const getUserInfo = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${baseUrl}user/`, { headers: getHeaders() });
            const { user } = response.data;

            setUser({ user });
            setUserContext({ ...userContext, user });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                await logout();
                sendMessage('Session expired. Please login again.', 'error');
            } else {
                sendMessage('Something went wrong.', 'error');
            }
        }
    };

    const updateUser = async (updatedData) => {
        setLoading(true);
        try {
            const response = await axios.put(`${baseUrl}user/`, updatedData, { headers: getHeaders() });
            const { user } = response.data;

            setUser({ user: { user } });
            setUserContext({ ...userContext, user: { user } });

            setLoading(false);
            sendMessage({ message: 'User information updated successfully', type: 'success' });
        } catch (err) {
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                await logout();
                sendMessage('Session expired. Please login again.', 'error');
            } else {
                sendMessage('Something went wrong.', 'error');
            }
        }
    };

    const clearUser = () => {
        setUser({ user: {} });
        setUserContext({ user: {} });
    }

    return { user, loading, getUserInfo, updateUser, clearUser };
}

export default useUser;